import React from 'react'
import { HiOutlineMail } from 'react-icons/hi';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Contacto.scss';
import { Text } from '..';

import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { FaTiktok, FaDiscord, FaArtstation } from 'react-icons/fa';


const Footer = () => {
  return (
    <>
      <h2 className='head-text'>
        <Text arrayTexts={["Contacto", "Contact", "Contact"]} ></Text>
      </h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <HiOutlineMail />
          <a href="mailto:business@carlosrio.com" className="p-text">business@carlosrio.com</a>
        </div>
      </div>

      <div className="app__social">
        <div>
          <a href='https://instagram.com/crformula?igshid=YmMyMTA2M2Y='>
            <AiFillInstagram />
          </a>
        </div>
        <div>
          <a href='https://www.youtube.com/channel/UCqbAZbE0JAa-89p_0i0b9rw'>
            <AiFillYoutube />
          </a>
        </div>
        <div>
          <a href='https://vm.tiktok.com/ZMFa2XvJa/'>
            <FaTiktok />
          </a>
        </div>
        <div>
          <a href='https://discord.gg/FR9jqPFVxZ'>
            <FaDiscord />
          </a>
        </div>
        <div>
          <a href='https://www.artstation.com/carlos_rio'>
            <FaArtstation />
          </a>
        </div>
        {/* <div>
      <a href='https://www.instagram.com/cquintanalavin/?igshid=YmMyMTA2M2Y%3D'>
        <FaPatreon />
      </a>
    </div> */}
      </div>
      <div className='contact-author'>
        <p>Want a page like this? <a href="mailto:info@victorgutierrez.tech">Contact the author</a></p>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Footer, 'app__contact'), 'contacto', 'app__blackbg');



