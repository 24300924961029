import React from 'react'

import { videos } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper';
import './Portada.scss';

import {Text} from '..';

const Portada = () => {

  const video = false;

  return (
    <div className='app__header-info'>
      <div className='app__header-info-text'>
        <h1><Text arrayTexts={["Bienvenido a mi página", "Welcome to my site", "Bienvenue sur ma page"]} ></Text></h1>
        <h3><Text arrayTexts={["El diseño 3D es mi pasión", "3D art is my passion", "La conception 3D est ma passion"]} ></Text></h3>
        {/* <h4>Specially motorport</h4> */}
        {/* <h2><Text arrayTexts={["Déjame enseñarte de lo que soy capaz", "Let me show you what i can do", "Laissez-moi vous montrer de quoi je suis capable"]} ></Text></h2> */}
      </div>
      {!video && (
        <img src='portada.jpg' alt='profile_bg' />
      )}
      {/* {video && (
        <video autoPlay muted loop>
          <source src={videos.film} type="video/mp4" />
        </video>
      )} */}
    </div>
  )
}

export default AppWrap(MotionWrap(Portada, 'app__header'),'portada', 'app__primarybg');