import React, { useState, useContext, useEffect } from 'react';

import { MyContext } from '../MyContext';

import { images } from '../../constants';
import './NavbarFile.scss'

const NavbarFile = () => {
  const { language, setLanguage } = useContext(MyContext);

  const menuArray = ['portada', 'sobre mi', 'portfolio', 'competencias', 'trabajo', 'carta', 'contacto', 'artstation'];

  const [scrollPos, setScrollPos] = useState(0);

  const [hoverMenu, setHoverMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const styles_container = {
    padding: (scrollPos < 500 || hoverMenu) ? '4rem 3rem' : '1rem 3rem',
  };

  const styles_hide = {
    opacity: (scrollPos < 500  || hoverMenu) ? '1' : '0',
  };

  const styles_logo = {
    width: (scrollPos < 500  || hoverMenu) ? '180px' : '100px',
    height: (scrollPos < 500  || hoverMenu) ? '90px' : '50px'
  };
  
  var buttons;

  if (language === 0) {
    buttons = menuArray;
  } else if (language === 1) {
    buttons = ['home', 'about me', 'portfolio', 'skills', 'work', 'letter', 'contact','artstation'];
  } else {
    buttons = ['accueil', 'à propos de moi', 'portfolio', 'compétences', 'travail', 'lettre', 'contact', 'artstation'];
  }

  return (
    <nav className='app__navbar' style={styles_container} onMouseEnter={() => setHoverMenu(true)} onMouseLeave={() => setHoverMenu(false)}>
      <div className='app__navbar-logo'>
        <img src={images.signature} style={styles_logo} alt='logo' /> 
      </div>
      <ul className='app__navbar-links'>
        {buttons.map((item, i) => (
          <li className='app__flex p-text' key={`link-${item}`}>
            <div style={styles_hide}/>
            {item === 'artstation' && (
              <a href={'https://www.artstation.com/carlos_rio'} style={styles_hide}>{item}</a>
            )} 
            {item !== 'artstation' && (
              <a href={`#${menuArray[i]}`} style={styles_hide}>{item}</a>
            )} 
          </li>
        ))}
      </ul>
      {/* car mecanics journecy */}
      <div className='app__navbar-flags' style={styles_hide}>
        <img src={images.eng} alt='eng' onClick={() => setLanguage(1)}></img>
        <img src={images.esp} alt='esp' onClick={() => setLanguage(0)}></img>
        <img src={images.fr} alt='fr' onClick={() => setLanguage(2)}></img>
      </div>
      {/* <div className='app__navbar-menu'>
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {buttons.map((item, i) => (
                <li key={item}>
                  <a href={`#${menuArray[i]}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div> */}
    </nav>
  )
}

export default NavbarFile