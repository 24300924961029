import React, { useState, useEffect, useContext } from 'react'

import { AppWrap } from '../../wrapper';
import { images } from '../../constants'
import { client } from '../../client';
import { Text } from '..';
import { MyContext } from '../../components/MyContext';
import { ParallaxBanner } from 'react-scroll-parallax';

import './Carta.scss';

const Header = () => {
    const [letter, setLetter] = useState([]);
    const { language } = useContext(MyContext);

    useEffect(() => {
        const query = '*[_type == "letter"]| order(index asc)';

        client.fetch(query).then((data) => {
            setLetter(data);
        })

    }, [])

    return (
        <div className='app__letter-container' style={{ height: '100vh', width: '100vw', left: 0, overflow: 'hidden', backgroundColor: 'transparent' }}>
            <ParallaxBanner
                layers={[
                    { image: '/circuit.jpg', speed: 30 },
                ]}
                className="aspect-[2/1]"
                style={{ height: '100vh', zIndex: 0, position: 'absolute', opacity: 0.3 }}
            />
            <div className='app__letter-inner' style={{zIndex: 1}}>
                <h2 className='head-text'>
                    <Text arrayTexts={["¿Quien soy yo?", "Who am i?", "Qui est moi?"]} ></Text>
                </h2>
                <div className='app__letter-text'>
                    {letter.length > 0 && (
                        <>
                            <p>{letter[language].text[0]}</p>
                            <br></br>
                            <p>{letter[language].text[1]}</p>
                            <br></br>
                            <p>{letter[language].text[2]}</p>
                            <br></br>
                            <Text arrayTexts={["Sinceramente,", "Sincerely,", "Sincèrement,"]} ></Text>
                            <p>Carlos</p>
                        </>
                    )}
                </div>
                <img src={images.signature} alt='signature'></img>
            </div>
        </div>
    )
}

export default AppWrap(Header, 'carta', 'app__blackbg');