import React from 'react'
import { ScaleLoader } from 'react-spinners';

import './Loader.scss';
import { images } from '../../constants'

const Loader = () => {
  return (
    <>
        <div className='loader_container'>
            <img src={images.signature} alt="logo" />
            <ScaleLoader color='white' loading={true}/>
        </div>
    </>
  )
}

export default Loader