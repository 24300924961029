import React from 'react'

import { useContext } from 'react';
import { MyContext } from '../components/MyContext';

const Text = ({ arrayTexts }) => {

    const { language } = useContext(MyContext);

    return (
        <>{arrayTexts[language]}</>
    )
}

export default Text