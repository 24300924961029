import React, { useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { FaArtstation } from 'react-icons/fa';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Portafolio.scss';

const Portafolio = () => {

  const carsName = [
    'Debranded Ferrari 458',
    'Debranded Ferrari 599',
    'Debranded Chevrolet Corvette',
    'Debranded Joss JT1',
    'Red Bull RB1',
    'Jordan EJ13'
  ]

  const carsDesc = [
    'Debranded Ferrari 458 for hyper-realistic mobile game (Tigloos games). Mesh is divided in multiple parts with empties in order to match the game hierarchy. Cycles Render.',
    'Debranded Ferrari 599 for hyper-realistic mobile game (Tigloos games).Mesh is divided in multiple parts with empties in order to match the game hierarchy. Cycles Render.',
    'Debranded Corvette for hyper-realistic mobile game (Tigloos games). Mesh is divided in multiple parts with empties in order to match the game hierarchy. Cycles Render.',
    'Debranded Joss JT1 for hyper-realistic mobile game (Tigloos games). Mesh is divided in multiple parts in order to match the game hierarchy. Cycles Render.',
    'Presenting high-poly Red Bull RB1 for Assetto Corsa simulator. This model stands as the inaugural release from the new modding group, CRFormula. Devoting over 200 hours solely to its design, I employed cutting-edge accuracy techniques to ensure every aspect of the real car was faithfully replicated. I also created striking skins for iconic circuits including Australia, Imola, Europe, and Monaco. Particularly noteworthy is the Monaco skin inspired by the Star Wars franchise. This exclusive livery was donned by Red Bull during the 2005 Monaco Grand Prix, coinciding with the release of Star Wars: Episode III. Every flame and detail from the original skin has been recreated using projection techniques',
    'Jordan EJ13 from 2003 modeled and textured from scratch. High detail model.'
  ]

  const [indexCar, setIndexCar] = useState(0)

  const carImages = [
    ['/ferrari458/458_1.jpg', '/ferrari458/458_2.jpg', '/ferrari458/458_3.jpg', '/ferrari458/458_4.jpg'],
    ['/ferrari599/599_1.jpg', '/ferrari599/599_2.jpg', '/ferrari599/599_3.jpg', '/ferrari599/599_4.jpg'],
    ['/corvette/corveto1.jpg', '/corvette/corveto2.jpg', '/corvette/corveto3.jpg', '/corvette/corveto4.jpg'],
    ['/joss/josito1.jpg', '/joss/josito2.jpg', '/joss/josito3.jpg', '/joss/josito4.jpg'],
    ['/rb1/1.jpg', '/rb1/2.jpg', '/rb1/3.jpg', '/rb1/4.jpg'],
    ['/jordan/1.jpg', '/jordan/2.jpg', '/jordan/3.jpg', '/jordan/4.jpg']
  ]

  const carArtStationLinks = [
    'XgaaPR',
    '9EBByO',
    'DvYLQG',
    'Nyrq4N',
    'LRk1yv',
    'RnoKYv'
  ]

  const [timer, setTimer] = useState(4);

  const iniciarContador = (index) => {
    setTimer(0)
    setIndexCar(index)
    setInterval(() => {
      setTimer(prevTimer => prevTimer + 0.01)
    }, 10);
  }

  const [index, setIndex] = useState(0);

  return (
    <>
      {/* <h2 className='head-text'>
        <Text arrayTexts={["Una muestra de mi trabajo", "A taste of my work", "Un aperçu de mon travail"]} ></Text>
      </h2>
      <ImageGallery items={renders}/> */}
      <div className='app__testimonials'>
        <div className='data'>
          <div className='car_images'>
            {carImages[indexCar].map((image, index) => (
              <div className='image_container' style={{ opacity: timer > (0.5 + index) ? 1 : 0 }}>
                <img key={index} src={image} style={{ opacity: timer > (0.5 + index) ? 1 : 0 }}></img>
              </div>
            ))}
          </div>
          <div className='car_description'>
            <div className='title'>
              <button onClick={() => setIndexCar((prevIndex) => (prevIndex - 1 + 6) % 6)}><HiChevronLeft style={{ color: 'white' }} size={50} /></button>
              <h2>{carsName[indexCar + index]}</h2>
              <button onClick={() => setIndexCar(indexCar + 1)}><HiChevronRight style={{ color: 'white' }} size={50} /></button>
            </div>
            <p className='description'>{carsDesc[indexCar]}</p>
            <a target='_blank' href={`https://www.artstation.com/artwork/${carArtStationLinks[indexCar]}`}>
              <FaArtstation />
            </a>
          </div>
        </div>
        <div className='thumbnails'>
          <button  style={{opacity: index == 0 ? 0 : 100}} onClick={() => setIndex(prevIndex => Math.max(prevIndex - 1, 0))}>
            <HiChevronLeft style={{ color: 'white' }} size={50} />
          </button>
          {carImages.slice(index, index + 4).map((image, idx) => (
            <button onClick={() => iniciarContador(index + idx)}>
              <img key={idx} src={image[0]}></img>
            </button>
          ))}
          <button style={{opacity: index == 2 ? 0 : 100}} onClick={() => setIndex(prevIndex => Math.min(prevIndex + 1, carImages.length - 4))}>
            <HiChevronRight style={{ color: 'white' }} size={50} />
          </button>
        </div>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Portafolio, 'app__testimonials'), 'portfolio', 'app__primarybg');