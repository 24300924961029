import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { Parallax } from 'react-parallax'

import { AppWrap, MotionWrap } from '../../wrapper';
import './SobreMi.scss';
import { urlFor, client } from '../../client';
import { Text } from '..';
import { MyContext } from '../../components/MyContext';

const SobreMi = () => {
  const [abouts, setAbouts] = useState([])
  const { language } = useContext(MyContext);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query)
      .then((data) => setAbouts(data))
  }, []);

  const delayAparition = 0.4;

  const opacity = (percentage, index) => {
    if (percentage < delayAparition + index/10) {
      return 0;
    } else if (percentage < delayAparition + 0.1 + index/10) {
      return 10 * (percentage - (delayAparition + index/10));
    } else {
      return 1;
    }
  }

  return (
    <>
      <h2 className='head-text'>
        <Text arrayTexts={["Un pequeño paseo por mi vida", "A little walk through my life", "Une petite promenade dans ma vie"]} ></Text>
      </h2>
      <Parallax
        style={{ zIndex: 3 }}
        strength={200}
        renderLayer={(percentage) => (
          <div className='app__profiles'>
            {abouts.map((about, index) => (
              <motion.div
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, type: 'tween' }}
                className='app__profile-item'
                key={about.title + index}
              >
                <img src={urlFor(about.imgUrl)} alt={about.title} style={{ opacity: opacity(percentage, index)}}/>
                <h2 className='bold-text' style={{ marginTop: 20, opacity: opacity(percentage, index)}}>{about.title[language]}</h2>
                <p className='p-text' style={{ marginTop: 20, opacity: opacity(percentage, index)}}>{about.description[language]}</p>
              </motion.div>
            ))}
          </div>
        )}>
      </Parallax>
    </>
  )
}

export default AppWrap(
  MotionWrap(SobreMi, 'app__about'),
  'sobre mi',
  "app__blackbg "
);                