import React, { useState, useEffect } from 'react';

import { AboutFile, FooterFile, HeaderFile, Skills, TestimonialsFile, Letter, WorkFile, Loader } from './container';
import { NavbarFile } from './components'
import { MyContextProvider } from './components/MyContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import './app.scss'

const App = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(0);
    }, 1750);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    setTimeout(() => {
      setOpacity(1);
    }, 2250);
  }, []);

  return (
    <div className='app' style={{opacity: opacity, transition: 'opacity 0.25s ease-in-out'}}>
      {isLoading ? <Loader /> : (
        <>
          <ParallaxProvider>
            <MyContextProvider>
              <NavbarFile />
              <HeaderFile />
              <AboutFile />
              <TestimonialsFile />
              <Skills />
              <WorkFile />
              <Letter />
              <FooterFile />
            </MyContextProvider>
          </ParallaxProvider>
        </>
      )}
    </div>
  )
}

export default App