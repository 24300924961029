import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Competencias.scss'
import { Text } from '..';
import { MyContext } from '../../components/MyContext';
import { ParallaxBanner } from 'react-scroll-parallax';

const Competencias = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const { language } = useContext(MyContext);

  useEffect(() => {
    const query = '*[_type == "experiences"] | order(year desc)';
    const skillsQuery = '*[_type == "skills"] | order(index asc)';

    client.fetch(query).then((data) => {
      setExperiences(data);
    })

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    })

  }, [])


  return (
    <>
      <ParallaxBanner
        layers={[
          { image: '/desktop.jpg', speed: 30 },
        ]}
        className="aspect-[2/1]"
        style={{ height: '100vh', zIndex: 0, position: 'absolute', opacity: 0.3 }}
      />
      <div style={{zIndex: 1}}>
        <h2 className='head-text'>
          <Text arrayTexts={["Habilidades & Experiencia", "Skills & Experience", "Compétences & Expérience"]} ></Text>
        </h2>
        <div className='app__skills-container'>
          <motion.div className='app__skills-list'>
            {skills.map((skill) => (
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__skills-item app__flex"
                key={skill.name}
              >
                <div
                  className='app__flex'
                  style={{ backgroundColor: skill.bgColor }}
                >
                  <img src={urlFor(skill.icon)} alt={skill.name} />
                </div>
                <p className='p-text'>{skill.name}</p>
              </motion.div>
            ))}
          </motion.div>
          <motion.div className="app__skills-exp">
            {experiences.map((experience) => (
              <motion.div
                className="app__skills-exp-item"
                key={experience.year}
              >
                <div className='app__skills-exp-year'>
                  <p className='bold-text'>{experience.year}</p>
                </div>
                <motion.div className='app__skills-exp-works'>
                  {experience.works.map((work) => (
                    <>
                      <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className='app__skills-exp-work'
                        data-tip
                        data-for={work.name}
                        key={work.name}
                      >
                        <h4 className='bold-text'>{work.name[language]} - {work.company}</h4>
                        <p className='p-text>'>{work.desc[language]}</p>
                      </motion.div>
                    </>
                  ))}
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </>
  )
}

export default AppWrap(
  Competencias,
  'competencias',
  'app__blackbg'
);