import logo from '../assets/logo.png'
import mobile from '../assets/mobile.png';
import email from '../assets/email.png';
import ferrari from '../assets/ferrariNORMALIZAO.png';
import signature from '../assets/firma_blanco_png.png';
import esp from '../assets/es.svg';
import fr from '../assets/fr.svg';
import eng from '../assets/gb.svg';

// eslint-disable-next-line
export default {
    logo,
    mobile,
    email,
    ferrari,
    signature,
    esp,
    fr,
    eng
};